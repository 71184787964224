import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import OrderForms from "../components/OrderForms";
import "url-search-params-polyfill";

// eslint-disable-next-line
export const SuppliesPageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  orderforms,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            <OrderForms forms={orderforms} />
            <>
              <hr />
              <h4>Hawkes Bay</h4>
              <a
                className="has-text-primary"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hawkesbayscl.co.nz/referrers/order-laboratory-consumables/"
              >
                <strong>
                  Please order via Awanui Labs (SCL) online ordering.
                </strong>
              </a>
            </>
            <>
              <hr />
              <h4>Whanganui / MidCentral / Tarawhiti</h4>
              <strong>Order as normal via Medlab Central.</strong>
            </>
          </div>
        </div>
      </div>
    </section>
  );
};

const Supplies = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SuppliesPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        orderforms={post.frontmatter.orderforms}
      />
    </Layout>
  );
};

Supplies.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Supplies;

export const pageQuery = graphql`
  query SuppliesByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        orderforms {
          document {
            publicURL
            name
          }
          region
          email
        }
      }
    }
  }
`;
