import React from 'react'
import PropTypes from 'prop-types'

const OrderForms = ({ forms }) => (
  <>
    {forms.map(form => (
      <>
        <hr />
        <h4>{form.region}</h4>
        <a className="has-text-primary" target="_blank" rel="noopener noreferrer" href={form.document.publicURL}>
          <strong>Download an order form for {form.region} stores requests here.</strong>
        </a>
        <p>Once completed, please email it to <a href="mailto:{form.email}" className="has-text-primary"><strong>{form.email} </strong></a>or send via the chilly bin system.</p>
      </>
    ))}
  </>
)

OrderForms.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.shape({
    region: PropTypes.string,
    document: PropTypes.object,
    email: PropTypes.string,
  })),
}

export default OrderForms
